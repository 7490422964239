<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <g>
      <path
        style="fill:#C7CFE2;"
        d="M446.5,151.405c-4.759-0.914-9.431,2.173-10.379,6.948l-3.414,17.269
		C401.504,106.77,333.089,61.793,256,61.793c-18.888,0-37.56,2.707-55.482,8.052c-4.672,1.388-7.336,6.302-5.94,10.974
		c1.388,4.672,6.293,7.336,10.974,5.94c16.294-4.853,33.268-7.31,50.449-7.31c70.931,0,133.813,41.854,161.689,105.728
		l-17.155-14.022c-3.785-3.095-9.336-2.526-12.423,1.25c-3.086,3.776-2.526,9.336,1.25,12.423l39.853,32.569
		c1.603,1.311,3.577,1.992,5.587,1.992c1,0,2.017-0.172,2.991-0.518c2.914-1.052,5.069-3.561,5.673-6.595l9.983-50.492
		C454.388,157,451.276,152.353,446.5,151.405z"
      />
      <path
        style="fill:#C7CFE2;"
        d="M306.449,425.241c-16.285,4.853-33.259,7.31-50.449,7.31c-70.932,0-133.816-41.856-161.693-105.733
		l17.166,14.026c3.776,3.078,9.345,2.526,12.423-1.25c3.086-3.776,2.526-9.336-1.25-12.423l-39.862-32.569
		c-2.406-1.965-5.646-2.5-8.577-1.474c-2.914,1.052-5.069,3.561-5.673,6.595l-9.983,50.492c-0.94,4.785,2.173,9.431,6.948,10.379
		c0.577,0.113,1.155,0.163,1.725,0.163c4.129,0,7.819-2.914,8.655-7.112l3.414-17.269C110.496,405.23,178.911,450.207,256,450.207
		c18.897,0,37.569-2.707,55.482-8.052c4.672-1.388,7.336-6.311,5.94-10.974C316.034,426.508,311.147,423.828,306.449,425.241z"
      />
    </g>
    <g>
      <path
        style="fill:#AFB9D2;"
        d="M306.449,425.241c-16.285,4.853-33.259,7.31-50.449,7.31c-4.468,0-8.904-0.17-13.301-0.497
		c2.806,6.255,6.057,12.253,9.66,18.019c1.218,0.023,2.419,0.132,3.641,0.132c18.897,0,37.569-2.707,55.482-8.052
		c4.672-1.388,7.336-6.311,5.94-10.974C316.034,426.508,311.147,423.828,306.449,425.241z"
      />
      <path
        style="fill:#AFB9D2;"
        d="M269.301,79.945c-2.806-6.255-6.057-12.253-9.66-18.019c-1.218-0.022-2.419-0.132-3.641-0.132
		c-18.888,0-37.56,2.707-55.482,8.052c-4.672,1.388-7.336,6.302-5.94,10.974c1.388,4.672,6.293,7.336,10.974,5.94
		c16.294-4.853,33.268-7.31,50.449-7.31C260.468,79.448,264.904,79.618,269.301,79.945z"
      />
    </g>
    <circle style="fill:#FFC850;" cx="132.414" cy="141.241" r="132.414" />
    <circle style="fill:#FFDC64;" cx="132.414" cy="141.241" r="114.759" />
    <path
      style="fill:#FFC850;"
      d="M170.393,198.605c-51.495-0.908-94.417-43.815-95.342-95.309c-0.146-8.079,0.701-15.936,2.422-23.459
	c1.769-7.731-7.496-13.361-13.15-7.8c-21.404,21.048-33.092,51.938-27.71,85.351c6.52,40.472,39.181,73.133,79.654,79.654
	c33.412,5.384,64.302-6.306,85.351-27.709c5.56-5.654-0.071-14.919-7.802-13.15C186.304,197.901,178.46,198.748,170.393,198.605z"
    />
    <circle style="fill:#FFF082;" cx="180.966" cy="92.69" r="22.069" />
    <path
      style="fill:#E1A546;"
      d="M161.836,191.794c-8.095,7.25-18.543,11.241-29.422,11.241c-24.337,0-44.138-19.801-44.138-44.138
	v-8.828h44.138c4.879,0,8.828-3.948,8.828-8.828s-3.948-8.828-8.828-8.828H88.276v-8.828c0-24.337,19.801-44.138,44.138-44.138
	c10.879,0,21.336,3.991,29.431,11.242c3.62,3.25,9.224,2.948,12.466-0.69c3.25-3.629,2.94-9.216-0.69-12.466
	c-11.345-10.155-25.974-15.742-41.207-15.742c-34.069,0-61.793,27.724-61.793,61.793v8.828h-8.828c-4.879,0-8.828,3.948-8.828,8.828
	s3.948,8.828,8.828,8.828h8.828v8.828c0,34.069,27.724,61.793,61.793,61.793c15.224,0,29.854-5.587,41.198-15.742
	c3.629-3.25,3.939-8.836,0.69-12.466C171.052,188.853,165.465,188.543,161.836,191.794z"
    />
    <circle style="fill:#FFC850;" cx="379.586" cy="370.759" r="132.414" />
    <circle style="fill:#FFDC64;" cx="379.586" cy="370.759" r="114.759" />
    <path
      style="fill:#FFC850;"
      d="M417.566,428.122c-51.495-0.908-94.417-43.815-95.342-95.309c-0.146-8.079,0.701-15.936,2.422-23.459
	c1.769-7.731-7.496-13.361-13.15-7.8c-21.404,21.048-33.092,51.938-27.71,85.351c6.52,40.472,39.181,73.133,79.654,79.654
	c33.412,5.384,64.302-6.306,85.351-27.709c5.56-5.654-0.071-14.919-7.802-13.15C433.476,427.418,425.632,428.265,417.566,428.122z"
    />
    <circle style="fill:#FFF082;" cx="428.138" cy="322.207" r="22.069" />
    <path
      style="fill:#E1A546;"
      d="M388.414,363.997v-45.678c18.152,2.076,30.897,10.247,30.897,17.682c0,4.879,3.948,8.828,8.828,8.828
	c4.879,0,8.828-3.948,8.828-8.828c0-18.226-20.726-32.826-48.552-35.413v-9.277c0-4.879-3.948-8.828-8.828-8.828
	c-4.879,0-8.828,3.948-8.828,8.828v9.277c-27.826,2.586-48.552,17.187-48.552,35.413c0,26.056,26.29,35.399,48.552,41.521v45.678
	c-18.152-2.076-30.897-10.247-30.897-17.682c0-4.879-3.948-8.828-8.828-8.828s-8.828,3.948-8.828,8.828
	c0,18.226,20.726,32.826,48.552,35.413v9.277c0,4.879,3.948,8.828,8.828,8.828c4.879,0,8.828-3.948,8.828-8.828v-9.277
	c27.826-2.586,48.552-17.187,48.552-35.413C436.966,379.462,410.676,370.119,388.414,363.997z M339.862,336
	c0-7.436,12.745-15.607,30.897-17.682v40.765C347.93,352.124,339.862,345.832,339.862,336z M388.414,423.199v-40.765
	c22.828,6.958,30.897,13.251,30.897,23.082C419.31,412.953,406.566,421.124,388.414,423.199z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
